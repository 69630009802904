<template>
  <div>
    <businessModeBoardList @updateTabs="updateTabs"></businessModeBoardList>
  </div>
</template>
<script>
export default {
  methods: {
    updateTabs(item) {
      console.log(item);
      this.$emit("updateTabs", item);
    }
  }
};
</script>
